import React, { Component } from 'react';
import GlobalError from './GlobalError'
import config from "../config";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
		this.state = {
			hasError: false,
			error: {}
		};
		//console.log('location.href: ' + window.location.href);
    }
	
	logErrorToDb = () =>{
		const {error} = this.state
		var data = {
			type: 'Frontend Error',
			mesage: error.stack.toString()
		}
		fetch(config.BASE_URL + '/api/logerror', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			  'Accept': 'application/json'
			}
		})
		.then(data => data.json())
        .then(data => {
		})
        .catch(error => {});		
	}
	
	componentDidCatch(error, info) {
		this.setState({
			hasError: true,
			error: error
		}, () => {
			this.logErrorToDb();
		})		
		
	}
	static getDerivedStateFromError(error) {
		//console.log('We did catch', error);
		//return { hasError: true };
	}
  
	render() {
		const {hasError} = this.state	
		return hasError ? <GlobalError error={this.state.error} /> : this.props.children
	}
}
export default ErrorBoundary;
