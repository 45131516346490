import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import config from "../config";
class RenderMetaTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metatags: []
    }
    this.get = this.get.bind(this);
  }

	get(path) {
		const data = {
			path: path,
			site: 'flatpacksaustralia.com.au'
		}

		fetch(config.BASE_URL + '/api/meta/getpath', {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json'
			  }
			})
			.then(data => data.json())
			.then(data => {
				this.setState({
					metas: data.items
				})
			})
			.catch(error => console.log(error));
	}
	componentDidMount() {
		const path = window.location.pathname
		this.get(path);
	}
  render() {
		const {metas} = this.state
    return (
			<Helmet>
			{ metas && metas.length > 0 ? metas.map((meta, key) => (
					meta.metaname == 'title' ? <title>{meta.metavalue}</title> : <meta name={meta.metaname} content={meta.metavalue} />
				)) : ""
			}
			</Helmet>
		)
  }
}

export default RenderMetaTags
