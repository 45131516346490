import React, { Component } from 'react';
import config from "../config";
class GlobalError extends Component {
    
    /* constructor(props) {
        super(props);	
    } */

	render() {
		return (
            <>
            <header>
                <div className="secondary-header">
                    <div className="container">                               
                    <div className="welcome" style={{height: '20px'}}></div>    
                    </div>
                </div>
                <div className="container headertop">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3 logobox">
                            <a href="/"><img src="/images/flatpack-australia.png" alt="" /></a>
                            {config.VERSION}
                        </div>

                        <div className="col-xs-5 col-sm-3 col-md-3">
                            <div className="project-title"></div>
                        </div>

                        <div className="col-xs-7 col-sm-6 col-md-6 header-right">
                            Design Your Own Flatpack | Customer Support 1800 345 345
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 nav-main" style={{height:'0px'}}>
                    <div className="container">
                        <div className="row">
                            <nav className="col-xs-12 col-sm-12 col-md-12 navbar navbar-default pull-right">						
                            </nav>
                        </div>
                    </div>
                </div>
            </header>			
                <div className="dashboard-wrapper partscontent" style={{marginLeft: '0px'}}>
                    <div className="dashboardhome">
                        <div className="container-fluid dashboard-content jobspagecnt">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{textAlign: 'center'}}>
                                <h3>Some internal error has occurred in the service. Please contact the support team</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>		
		)
	}
}
export default GlobalError;
