import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';

import config from '../config';

import validate from '../components/Validation';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import '../assets/css/all.min.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/all.min.css';
import '../assets/css/animate.css';
import '../assets/css/nice-select.css';
import '../assets/css/owl.min.css';
import '../assets/css/jquery-ui.min.css';
import '../assets/css/magnific-popup.css';
import '../assets/css/flaticon.css';
import '../assets/css/main.css';

const JS = props => (
    <>
        {/* <ScriptTag type="text/javascript" src="../assets/js/jquery-3.3.1.min.js" /> */ }
        <ScriptTag type="text/javascript" src="../assets/js/modernizr-3.6.0.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/plugins.js" />
        <ScriptTag type="text/javascript" src="../assets/js/bootstrap.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/magnific-popup.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/jquery-ui.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/wow.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/waypoints.js" />
        <ScriptTag type="text/javascript" src="../assets/js/wow.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/nice-select.js" />
        <ScriptTag type="text/javascript" src="../assets/js/owl.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/counterup.min.js" />
        <ScriptTag type="text/javascript" src="../assets/js/paroller.js" />
        <ScriptTag type="text/javascript" src="../assets/js/main.js" />
        
    </>
)

const Header = () => {

    return (

        <>

            <header className="header-section">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="trade-only">
                            TRADE ONLY
                        </div>
                        <div className="logo">
                            <a href="/">
                                <img src="./images/logo-2-australia.png" alt="logo" />
                            </a>
                        </div>
                        <div className="made-in-australia">
                            <img src="./images/made-in-australia.png" alt="logo" />
                        </div>
                        <ul className="menu">
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <a href="#feature">Software Feature</a>
                            </li>
                            <li>
                                <a href="#contact">Contact Us</a>
                            </li>
                            <li>
                                <a href="#about">About Us</a>
                            </li>                            
                        </ul>
                        <div className="header-bar d-lg-none">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="header-right">
                            {
                                /* <select className="select-bar">
                                    <option value="en">En</option>
                                    <option value="Bn">Bn</option>
                                    <option value="pk">Pk</option>
                                    <option value="Fr">Fr</option>
                                </select> */ 
                            }
                            <a href={ config.CABINET_MAKER_URL } className="header-button d-none d-sm-inline-block">Login</a>
                        </div> 
                    </div>
                </div>
            </header>
        
        </>
    )

}

const Footer = () => {

    return (

        <>

            <footer className="footer-section bg_img">
                <div className="container">
                    <div className="footer-top">
                        <div className="logo">
                            <a href="#0">
                                <img src="/images/logo-2-australia-white.png" alt="logo" />
                            </a>
                        </div>
                        <ul className="social-icons d-none">
                            <li>
                                <a href="https://www.facebook.com/flatpackswa/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                            </li>
                            <li>
                                <a href="#0"><i className="fab fa-linkedin"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-bottom">
                        <ul className="footer-link">
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <a href="#feature">Software Features</a>
                            </li>
                            <li>
                                <a href="#contact">Contact Us</a>
                            </li>
                            <li>
                                <a href="#about">About us</a>
                            </li>
                            
                        </ul>
                    </div>
                    <div className="copyright">
                        <p>
                            Copyright © 2020. Flatpacks Australia | <a href="#0">Terms & Conditions</a>
                        </p>
                    </div>
                </div>
            </footer>
            <JS />
        </>
    )

}

const Preloader = () => {

    return (

        <>

            <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
            <div className="overlay"></div>
        
        </>
    )

}

const Banner = () => {

    return (

        <>

            <section className="banner-1 bg_img oh" data-background="linear-gradient(#51BD58, #BFDE8E)">
                {/* <div className="dot-1 d-none d-lg-block">
                    <img src="./assets/images/banner/dot-big.png" alt="banner" />
                </div>
                <div className="dot-2 d-none d-lg-block">
                    <img src="./assets/images/banner/dot-big.png" alt="banner" />
                </div>
                <div className="dot-3">
                    <img src="./assets/images/banner/dot-sm.png" alt="banner" />
                </div>
                <div className="dot-4">
                    <img src="./assets/images/banner/dot-sm.png" alt="banner" />
                </div> */ }
                <div className="banner-1-shape d-none d-lg-block">
                    <img src="./assets/css/img/curve-with-mac.png" alt="css" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="banner-content-1 cl-white">
                                <h1 className="title">An online platform for Australian Cabinet Makers, Builders and Tradies</h1>
                                <p>
                                Sign up to Australian’s #1 (we think where we are) platform for Tradies for cabinet renovation and we will send you stubby holder in the mail!
                                </p>
                                <div className="banner-button-group">
                                    <a href={config.CABINET_MAKER_URL} className="button-4">Sign up</a>                                  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            
                        </div>
                    </div>
                </div>
            </section>

        </>

    )

}

const Sponsors = () => {

    return (

        <>

            <div className="sponsor-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="spon-cont">
                                <p>Our Partners</p>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="sponsor-slider owl-theme owl-carousel">
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/blum.png" alt="partners" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/cmawa.png" alt="partners" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/corian.png" alt="partners" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/formica.png" alt="partners" />
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-4">
                        
                        <div className="col-lg-9 offset-lg-3">
                            <div className="sponsor-slider owl-theme owl-carousel">
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/hafele.png" alt="partners" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/laminex.png" alt="partners" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/polytec.png" alt="partners" />
                                </div>
                                <div className="sponsor-thumb">
                                    <img src="./assets/css/img/smartstone.png" alt="partners" />
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

const Features = () => {

    return (

        <section className="amazing-feature-section pos-rel" id="feature">
            <div className="shape-container oh">
                <div className="bg_img bg-img-middle feature-background" data-background="linear-gradient(180deg, #51BD58 35.65%, #BFDE8E 97.07%)"></div>
                <div className="feature-top-shape d-none d-lg-block">
                    <img src="./assets/css/img/curve-bottom-transparent.png" alt="css" />
                </div>
            </div>
            <div className="topper-feature oh padding-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-header left-style mb-lg-0 pos-rel">
                                <h5 className="cate underline">Explore Amazing Features <span></span></h5>
                                <h2 className="title">Order your custom made cabinetry online now</h2>
                                <p className="with-our-wide">With our wide range of features, you can build a wide range of custom cabinets and place orders in just a few minutes.</p>
                                <div className="downarrow d-none d-lg-block">
                                    <img src="./assets/images/feature/downarrow.png" alt="feature" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <a href="https://videos.sproutvideo.com/embed/489ddab51f1fe1c5c0/486000a193b1d973" className="feature-video-area popup">
                                <div className="thumb">
                                    <img src="./assets/css/img/flatpack-bilog.png" alt="feature" />
                                </div>
                                <div className="button-area">
                                   
                                    <div className="video-button">
                                        <i className="flaticon-play"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="amazing-feature-bottom padding-top padding-bottom pb-lg-0 pt-lg-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-5">
                            <div className="section-header left-style cl-white">
                                <h2 className="title">Explore the benefits of our online platform</h2>
                                <p>Numerous features that make it easy to customize cabinet sizes, materials, brands, colours, and so on.</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row justify-content-center mb-30-none">
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src="./assets/css/img/manage-job-easily.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Manage Jobs Easily</h5>
                                            <p>Every job has a separate project.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item active">
                                        <div className="am-thumb">
                                            <img src="./assets/css/img/customize-cabinets.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Customize Cabinets</h5>
                                            <p>Customize Cabinets as you need</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src="./assets/css/img/specialist.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">We are specialist in kitchen design</h5>
                                            <p>Using the latest technologies and brands.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src="./assets/css/img/24hr-support.png" alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Need help give us a call and talk to a real Aussie</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Coverage = () => {

    return (

        <section className="coverage-section padding-top padding-bottom" id="coverage">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="section-header left-style coverage-header">
                            <h5 className="cate">Our stats say more than any words</h5>
                            <h2 className="title">Apps Without Borders</h2>
                            <p>
                                Mosta app are growing by 300% every year with a steady love from users around the world. We are also close to achieving 10 million cumulative downloads.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="coverage-right-area text-lg-right">
                            <div className="rating-area">
                                <div className="ratings">
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                </div>
                                <span className="average">5.0 / 5.0</span>
                            </div>
                            <h2 className="amount">312,921+</h2>
                            <a href="#0">Total User Reviews <i className="fas fa-paper-plane"></i></a>
                        </div>
                    </div>
                </div>
                <div className="coverage-wrapper bg_img" data-background="./assets/images/bg/world-map.png">
                    <div className="border-item-1">
                        <span className="name">North America</span>
                        <h2 className="title">70.7%</h2>
                    </div>
                    <div className="border-item-2">
                        <span className="name">Asia</span>
                        <h2 className="title">14.4%</h2>
                    </div>
                    <div className="border-item-3">
                        <span className="name">North Europe</span>
                        <h2 className="title">8.4%</h2>
                    </div>
                    <div className="border-item-4">
                        <span className="name">South America</span>
                        <h2 className="title">1.8%</h2>
                    </div>
                    <div className="border-item-5">
                        <span className="name">Africa</span>
                        <h2 className="title">1.8%</h2>
                    </div>
                    <div className="border-item-6">
                        <span className="name">Australia</span>
                        <h2 className="title">3%</h2>
                    </div>
                </div>
            </div>
        </section>

    )
}

const Pricing = () => {

    return (

        <section className="pricing-section padding-top oh padding-bottom pb-md-0 bg_img pos-rel" data-background="linear-gradient(#51BD58, #BFDE8E)" id="contact">
            <div className="top-shape d-none d-md-block">
                <img src="./assets/css/img/top-shape.png" alt="css" />
            </div>
            <div className="bottom-shape d-none d-md-block">
                <img src="./assets/css/img/bottom-shape.png" alt="css" />
            </div>
            <div className="container">
                <div className="range-wrapper-2">
                    <div className="pricing-range">
                        <div className="pricing-range-top pt-0">
                            <div className="tags-area mb-4">
                                <h3 className="tags">Contact Flatpacks Australia</h3>
                            </div>
                            <div className="amount-area m-4">
                                <div className="item text-left pr-4">
                                    <p class="mb-4">Flat Packs Australia is a family-owned custom cabinetry supplier located south of Perth.</p>
                                    <p><strong>Phone:</strong> <a href="tel:1800 4978143">1800 4978143</a></p><p class="mb-4"><strong>Email:</strong> <a href="mailto:sales@flatpacksaustralia.com.au">sales@flatpacksaustralia.com.au</a></p>
                                    <hr className="pb-2" />
                                    <h6 className="mb-2">Perth Factory &amp; Showroom:</h6>
                                    <p className="mb-4">8 Geelong Court<br />Bibra Lake WA</p>
                                    <h6>Pickup</h6>
                                    <p>Monday - Thursday: 7.00 am to 4.00pm<br />Friday: 7.00 am to 3.00pm<br />Saturday: By Appointment</p>
                                </div>
                                <div className="item">
                                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1OXT0FOcfz72ZoZs_vux0-tB7OnM" width="425" height="400"></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-range-bottom"></div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Video = () => {

    return (

        <>

            <section className="banner-1 bg_img oh" data-background="https://www.flatpackswa.com.au/wp-content/uploads/2019/07/Flat-Packs-WA-Home-Hero-Video.mp4">
                <div className="banner-1-shape d-none d-lg-block">
                    <img src="./assets/css/img/curve-with-mac.png" alt="css" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="banner-content-1 cl-white">
                                <h1 className="title">An online platform for Australian Cabinet Makers & Builders and Tradies</h1>
                                <p>
                                Sign up to Australian’s №1 platform for Tradies for kitchen Renovation and we will send you stubby holder in the mail!
                                </p>
                                <div className="banner-button-group">
                                    <a href="#0" className="button-4">Sign up</a>                                  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            
                        </div>
                    </div>
                </div>
            </section>

        </>

    )

}

const Feature = () => {

    return (

        

        <section id="about" className="feature-section padding-top oh pos-rel">
            
            <div className="container">
                <div className="section-header mw-725">
                    <h5 className="cate underline">Extra Crazy Features that will</h5>
                    <h2 className="title">Boost Your Productivity </h2>
                 
                </div>
                
                <div className="row">
                    <div className="col-lg-12 rtl">
                        

                      
                    </div>
                </div>

            </div>

            
            <div className="bg-video-wrapper padding-top padding-bottom">

                <div className="bg-video">

                    <video width="100%" height="auto" autoPlay muted loop id="myVideo">
                        <source src="/assets/images/Flat-Packs-WA-Home-Hero-Video.mp4" type="video/mp4" />                        
                        Your browser does not support the video tag.
                    </video>

                </div>

            </div>

        </section>

    )

}

const How = () => {

    return (

        <section className="how-section padding-top padding-bottom" id="how">
            <div className="container">
                <div className="section-header">
                    <h5 className="cate underline">Just three steps away</h5>
                    <h2 className="title">Design and order your cabinets online</h2>
                 
                </div>
                <div className="row justify-content-xl-between justify-content-center mb-40-none">
                    <div className="col-lg-4 col-sm-6 col-xl-3">
                        <div className="how-item">
                            <div className="how-thumb">
                                <img src="./assets/css/img/register.png" alt="how" />
                            </div>
                            <div className="how-content">
                                <p>Register your business with our system</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xl-3">
                        <div className="how-item">
                            <div className="how-thumb">
                                <img src="./assets/css/img/place.png" alt="how" />
                            </div>
                            <div className="how-content">
                                <p>Place your order, and we ‘ll make it</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xl-3">
                        <div className="how-item">
                            <div className="how-thumb">
                                <img src="./assets/css/img/deliver.png" alt="how" />
                            </div>
                            <div className="how-content">
                                <p>Deliver to your doorstep</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

const Sections = () => {

    return (

        <>

            <Banner />
            <Sponsors />
            <Features />
            <How />
            <Pricing />
            <Feature />
         
        </>

    )

}

class Home extends Component {

    constructor(props) {

        super(props);

        this.state = {

            email: '',
            mobile: ''

        }

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);

    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleChange(e) {
        this.setState( { [e.target.name]: e.target.value } );
    }

    submit() {

        /*let valid = true;

        const name = '';

        const { email, mobile } = this.state;

        const isPhoneValid = validate.ValidatePhone(mobile);

        if (!this.validateEmail(email)) {            

            valid = false;
            ToastsStore.error("Email is not valid!! enter the valid email address");
        }

        if (!isPhoneValid) {
            valid = false;
            ToastsStore.error("Phone is not valid!! enter the valid phone number");
        }

        

        if (valid) {

            fetch(config.BASE_URL + '/api/register', {
                method: "POST",
                body: JSON.stringify({email, mobile, name}),
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json'
                }
            })
            .then(data => data.json())
            .then(data => {

                if (data.error) {
                    ToastsStore.error(`Error!! ${data.error}`);
                } else {
                    ToastsStore.success("Signup success!! please check your email to continue validate your account");  
                    this.setState( { email: '', phone: ''} );
                }
            })
            .catch(error => console.log(error));
        
        } */

        window.location = 'https://cabinet-maker.flatpacksaustralia.com.au/';

    }

    render() {

        return (

            <>
                <Preloader />

                <Header />

                <Sections />

                <section className="newslater-section oh pos-rel">            
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="newslater-area">
                                    <div className="section-header left-style cl-white">
                                        <h2 className="title text-center">Signup Now</h2>                                
                                    </div>
                                    <div className="subscribe-form text-center">
                                        {/* <input type="text" name="email" placeholder="Enter Your Email" onChange={this.handleChange} />
                                        <input type="text" name="mobile" placeholder="Enter Your Mobile Phone" onChange={this.handleChange} /> */}
                                        <button onClick={this.submit} type="submit">Start now</button>                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>

                <Footer />
                <ToastsContainer position={ToastsContainerPosition.BOTTOM_RIGHT} store={ToastsStore} lightBackground />
            </>

        );
    }
}

export default Home;